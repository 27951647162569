import { type Route } from './+types/_index'
import Page from '~/components/cms/page'
import { sdk } from '~/data/sdk.server'
import { getSampleResults } from '~/domain/calc/products.server'
import { enhancePageWithMarkdown } from '~/domain/cms/markdown.server'
import { makeMetaTitle, mergeRouteModuleMeta } from '~/utils/meta'

export const meta: Route.MetaFunction = mergeRouteModuleMeta(({ data }) => {
	return [
		{
			name: 'og:title',
			content: makeMetaTitle(data?.title),
		},
		{ title: makeMetaTitle(data?.title) },
	]
})

export async function loader() {
	const pageData = await sdk.Page({ slug: '/' })
	if (!pageData.page) throw new Response('Page not found', { status: 404 })
	return {
		pageContent: enhancePageWithMarkdown(pageData.page.content),
		title: pageData.page.pageTitle,
		sampleResults: await getSampleResults(),
	}
}

export default function Index({ loaderData }: Route.ComponentProps) {
	return loaderData.pageContent ? (
		<Page
			pageContent={loaderData.pageContent}
			sampleResults={loaderData.sampleResults}
		/>
	) : null
}
